import Vue from 'vue';
import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';
import { minValueRule, requiredRule, maxValueRule } from '@/vue-app/utils/form-rules';

// Application
import EditEmergencyFundGoalPlanBudgetService
  from '@/modules/flagship/edit-plan-goals/edit-emergency-fund-goal-plan/application/services/edit-emergency-fund-goal-plan-budget-service';
import EditEmergencyFundGoalPlanCurrentPlanService
  from '@/modules/flagship/edit-plan-goals/edit-emergency-fund-goal-plan/application/services/edit-emergency-fund-goal-plan-current-plan-service';
import EditEmergencyFundGoalPlanCurrentAmountService
  from '@/modules/flagship/edit-plan-goals/edit-emergency-fund-goal-plan/application/services/edit-emergency-fund-goal-plan-current-amount-service';

// Domain
import { CurrentAmountEntity }
  from '@/modules/flagship/edit-plan-goals/edit-emergency-fund-goal-plan/domain/entities/current-amount-entity';
import { CurrentPlanEntity }
  from '@/modules/flagship/edit-plan-goals/edit-emergency-fund-goal-plan/domain/entities/current-plan-entity';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class EditEmergencyFundYourCurrentAmountViewModel {
  @Inject(TYPES.EDIT_EMERGENCY_FUND_GOAL_PLAN_BUDGET_SERVICE)
  private readonly budget_service!: EditEmergencyFundGoalPlanBudgetService;

  @Inject(TYPES.EDIT_EMERGENCY_FUND_GOAL_PLAN_CURRENT_PLAN_SERVICE)
  private readonly current_plan_service!: EditEmergencyFundGoalPlanCurrentPlanService;

  @Inject(TYPES.EDIT_EMERGENCY_FUND_GOAL_PLAN_CURRENT_AMOUNT_SERVICE)
  private readonly current_amount_service!: EditEmergencyFundGoalPlanCurrentAmountService;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.goals-dashboard.edit-plan-goals.edit-emergency-fund-goal-plan.your_current_amount';

  readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  your_budge = {
    monthly_income: '0',
    monthly_expenses: '0',
    monthly_savings_capacity: 0,
    new_emergency_fund: '0',
  }

  current_plan_information: CurrentPlanEntity = {
    target_amount: '',
    current_amount: '',
    monthly_required_amount: '',
    final_investment_date: '',
    associated_product_id: '',
    emergency_fund_id: '',
  }

  new_target_amount = '';

  progress = '0';

  there_is_more_savings = 'no';

  strategy_selected = 'pocket';

  additional_amount = '';

  is_valid_form = false;

  max_value = 0;

  input_rules = {
    additional_amount: [
      requiredRule,
      (value: string) => minValueRule(value.replace(/[^0-9.-]/g, ''), '$1.00 MXN'),
      (value: string) => maxValueRule(value.replace(/[^0-9.-]/g, ''), `${this.getAmountFormatted(this.max_value)} MXN`, this.max_value),
    ],
  };

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  there_is_more_savings_options = [
    {
      label: this.translate('yes'),
      value: 'yes',
    },
    {
      label: this.translate('no'),
      value: 'no',
    },
  ];

  strategies = [
    {
      label: this.translate('sowos_pocket'),
      value: 'pocket',
    },
    {
      label: this.translate('other_inversion'),
      value: 'other',
    },
  ];

  initialize = () => {
    this.setInitialValues();
  }

  get show_additional_amount() {
    return this.there_is_more_savings === 'yes';
  }

  get is_pocket_strategy_selected() {
    return this.strategy_selected === 'pocket';
  }

  get is_continue_btn_disabled() {
    return !this.is_valid_form;
  }

  get additional_amount_formatted() {
    return this.getAmountFormatted(this.parseCurrencyToNumber(this.additional_amount));
  }

  getAmountFormatted(amount: number) {
    return currencyFormat(amount);
  }

  getBudgetInformation = () => (this.budget_service.getBudgetInformation());

  getCurrentPlanInformation = () => (this.current_plan_service.getCurrentPlanInformation());

  getCurrentAmountInformation = () => (this.current_amount_service.getCurrentAmountInformation());

  setCurrentAmountInformation = (current_amount: CurrentAmountEntity) => {
    this.current_amount_service.setCurrentAmountInformation(current_amount);
  }

  setInitialValues = () => {
    const budget_information = this.getBudgetInformation();
    const current_plan = this.getCurrentPlanInformation();
    const current_amount = this.getCurrentAmountInformation();
    this.your_budge = {
      monthly_income: String(budget_information.monthly_income),
      monthly_expenses: String(budget_information.monthly_expenses),
      monthly_savings_capacity: budget_information.monthly_savings_capacity,
      new_emergency_fund: String(budget_information.new_emergency_fund),
    };
    this.current_plan_information = { ...current_plan };
    this.new_target_amount = this.getAmountFormatted(budget_information.new_emergency_fund);
    this.progress = ((this.parseCurrencyToNumber(this.current_plan_information
      .current_amount) * 100) / budget_information.new_emergency_fund).toFixed(2);
    if (current_amount.additional_amount > 0) {
      this.additional_amount = String(current_amount.additional_amount);
      this.there_is_more_savings = current_amount.there_is_more_savings;
      this.strategy_selected = current_amount.strategy_selected;
    }
    this.max_value = budget_information.new_emergency_fund - this
      .parseCurrencyToNumber(this.current_plan_information.current_amount);
    this.max_value = this.parseCurrencyToNumber(this.max_value.toFixed(0)) - 1;
  }

  parseCurrencyToNumber = (currency: string) => parseFloat(currency.replace(/[^0-9.]/g, ''));

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = () => {
    const current_amount = {
      // eslint-disable-next-line max-len
      additional_amount: (this.additional_amount) ? this.parseCurrencyToNumber(this.additional_amount) : 0,
      there_is_more_savings: this.there_is_more_savings,
      strategy_selected: this.strategy_selected,
    };
    this.setCurrentAmountInformation(current_amount);
    this.view.$emit('nextStep');
  }
}
