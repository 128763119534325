
































































































































































import {
  Component, Vue,
} from 'vue-property-decorator';
import EditEmergencyFundYourCurrentAmountViewModel from '@/vue-app/view-models/components/goals-dashboard/edit-plan-goals/edit-emergency-fund-goal-plan/edit-emergency-fund-your-current-amount-view-model';

@Component({ name: 'EditEmergencyFundYourCurrentAmount' })
export default class EditEmergencyFundYourCurrentAmount extends Vue {
  edit_current_amount_view_model = Vue.observable(
    new EditEmergencyFundYourCurrentAmountViewModel(this),
  );

  mounted() {
    this.edit_current_amount_view_model.initialize();
  }
}
